export const AboutDetail = (props) => {
    return (
      <div id="about">
      <title>Nasa Mukti Kendra in Patna @9471414102 |  De-Addiction & Rehabilitation Center</title>
        <div className="container">
          y
        </div>
      </div>
    );
  };
  