import React from 'react'

var d = new Date();
var currYear = d.getFullYear();
export const Footer = (props) => {

  return (
    <>
      <div id='footer'>
        <div className='container '>
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <img style={{ height: '60px', overflow: 'hidden' }} align="left" src="img/sarlo.png" alt='logo'/>
              <div>
                <p style={{ marginTop: '80px' }}>Best Drug De-addiction & Alcohol Rehabilitation Centre in Patna offering best of the Deaddiction Treatment Programs in Patna, Bihar.</p>
              </div>{/*
            <div className="about-social-icon text-center">
              <div>
                <h3 style={{ textAlign: 'left' }}>Social Links</h3>
                <div className="flex-container">
                  <div className='facebook'>
                    <a className="facebook pop-upper without-counter" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fh2oroservices.com%2F&amp;t=h2oroservices" target="_blank" rel="noopener noreferrer" >
                      <i className="fa fa-facebook"></i>

                    </a>
                  </div>
                  <div className='linkedin'>
                    <a className="linkedin pop-upper without-counter" href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A%2F%2Fh2oroservices.com%2F&amp;title=h2oroservices&amp;summary=&amp;source=" target="_blank" rel="noopener noreferrer" style={{ width: "16.6667%" }}>
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </div>
                  <div className='instagram'>
                    <a className="instagram pop-upper without-counter" href="https://www.instagram.com//?utm_source=instagram_story&utm_medium=story_link&utm_campaign=${https://h2oroservices.com}" target="_blank" rel="noopener noreferrer" style={{ width: "16.6667%" }}>
                      <i className="fa fa-instagram"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
            </div>

            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <h3>Quick Links</h3>
              <ul className='contact-item'>
                <li>
                  <a href='/' className='page-scroll'>
                    Home
                  </a>
                </li>
                <li>
                  <a href='#about' className='page-scroll'>
                    About Us
                  </a>
                </li>
                <li>
                  <a href='#features' className='page-scroll'>
                    What We Do
                  </a>
                </li>
                <li>
                  <a href='#team' className='page-scroll' >
                    Gallrey
                  </a>
                </li>
                <li>
                  <a href='#services' className='page-scroll'>
                    Services
                  </a>
                </li>
                <li>
                  <a href='#contact' className='page-scroll'>
                    Contact
                  </a>
                </li>
                <li>
                  <a href='#testimonials' className='page-scroll'>
                    Testimonials
                  </a>
                </li>
                <li>
                  <a href='/privacy-policy' className='page-scroll'>
                    Privacy Policies
                  </a>
                </li>
              </ul>

            </div>

            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <h3>Our Programs</h3>
              <ul className='contact-item'>
                <li><p>Alcohol Addiction Treatment</p></li>
                <li><p>Cocaine Addiction Treatment</p></li>
                <li><p>Drug Addiction Treatment</p></li>
                <li><p>Heroin Addiction Treatment</p></li>
                <li><p>Marijuana Addiction Treatment</p></li>
                <li><p>Whitener Addiction Treatment</p></li>
                <li><p>Brown sugar addiction treatment</p></li>
                <li><p>Injection Addiction treatment</p></li>
                <li><p>Tablets addiction treatment</p></li>
              </ul>

            </div>

            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <h3>Contact Info</h3>
              <div className='contact-item'>
                <p>
                  <span>
                    <h3><i className='fa fa-phone'></i> Phone:</h3>
                  </span>{' '}
                  <a href="tel: 9044605877">
                    {props.data ? props.data.phone : 'loading'}</a>
                </p>
              </div>
              <div className='contact-item'>
                <p>
                  <span>
                    <h3><i className='fa fa-envelope-o'></i> Email:</h3>
                  </span>{' '}
                  <a href="mailto: sarthakjeevannasamuktikendra@gmail.com">
                    {props.data ? props.data.email : 'loading'}</a>
                </p>
              </div>
              <div className='contact-item'>
                <p>
                  <span>
                    <h3><i className='fa fa-whatsapp'></i> Whatsapp:</h3>
                  </span>{' '}
                  <a href="https://api.whatsapp.com/send?phone=919044605877" target="_blank" rel="noreferrer">
                    {props.data ? props.data.phone : 'loading'}</a>
                </p>
              </div>
              <div className='contact-item'>
                <p>
                  <span>
                    <h3><i className='fa fa-map-marker'></i> Location:</h3>
                  </span>{' '}
                  {props.data ? props.data.address : 'loading'}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cpyrit">

        <div className="cpyiner">
          <div className="et_pb_column et_pb_column_4_4 et_pb_column_4_tb_footer  et_pb_css_mix_blend_mode_passthrough et-last-child">

            <div className="et_pb_module et_pb_text et_pb_text_4_tb_footer ftr-btm-cont  et_pb_text_align_left et_pb_bg_layout_light">

              <div className="designli">©{currYear} Copyright&nbsp;By –  Sarthak Jeevan Nasha Mukti Kendra. All rights reserved.<span>Designed &amp; Developed by <a href="https://wappotix.com/" target="_blank" style={{ color: "#5adaff" }}> wappotix.com</a></span></div>
            </div>
          </div>
        </div>
      </div>


    </>
  )
}

