
import emailjs from 'emailjs-com'
import React, { useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";

const initialState = {
  from_name: '',
  email: '',
  mobile: '',
  adress: '',
}
export const Contact = (props) => {
  const [{ from_name,email, mobile,adress }, setState] = useState(initialState);
  const formRef = useRef();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { from_name, value } = e.target
    setState((prevState) => ({ ...prevState, [from_name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(from_name,email, mobile,adress)
    emailjs
      .sendForm(
        'service_jhvavwm', 'template_37fc66s', e.target, 'ApOnybWaQRwdj7Zxt'
      )
      .then(
        (result) => {
          alert('Your Call Back Request has been submitted , you will get a get from our team within 5-10 min. Thanks for your patience')
          clearState();
          formRef.current.reset();
          navigate("/thanks");
        },
        (error) => {
          console.log(error.text)
        }
      )
  }
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-6'>
            <div className='row'>
              <div className='section-title'>
                <h2>SUBMIT A CALL BACK REQUEST</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form ref={formRef} name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-10'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='from_name'
                        className='form-control'
                        placeholder='Full Name*'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                      <input
                      type='email'
                      id='email'
                      name='email'
                      className='form-control'
                      placeholder='Email'
                      required
                      onChange={handleChange}
                    />
                    <p className='help-block text-danger'></p>
                      <input
                        type='number'
                        id='mobile'
                        name='mobile'
                        className='form-control'
                        placeholder='Mobile Number*'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                      <div className='form-group'>
                        <textarea
                          name='adress'
                          id='adress'
                          className='form-control'
                          rows='3'
                          placeholder='Address*'
                          required
                          onChange={handleChange}
                        ></textarea>
                        <p className='help-block text-danger'></p>
                      </div>
                      <div id='success'></div>

                    </div>
                  </div>


                </div>

                <div id='success'></div>
                <button type='submit' className='btn btn-custom btn-lg'>
                  Get a Call Back
                </button>
              </form>
            </div>
          </div>

          <div className='col-md-5 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <img src="img/saraboutv2.png" className="img-responsive" style={{marginTop:'100px'}} alt="treatment" />{" "}
            </div>
          </div>
          {/*
         <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Address
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Phone
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
          </div> */}
          {/*
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : '/'}>
                      <i className='fa fa-twitter'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : '/'}>
                      <i className='fa fa-youtube'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        */}
        </div>
      </div>

    </div>
  )
}
