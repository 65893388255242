import React from "react";

export const Privacypolicy = (props) => {
    return (
        <div id='policy' >
            <div className="">
                <div className="policyinto">
                    <img src="img/banners/privacy.jpg" className="" alt="Privacy-policies" />{" "}

                </div>
                <div className="container">
                    <div className='section-title text-center' style={{ marginTop: '50px' }}>
                        <h2>Privacy Policy</h2>
                    </div>
                    <div className="policytext">
                        <h3><b><u>1. Introduction</u></b></h3>
                        <p>At Sarthak Jeevan Nasa Mukti Kendra, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website or utilize our services.</p>

                        <h3><b><u>2. Information We Collect</u></b></h3>
                        <p><b>We may collect the following types of information:</b></p>
                        <p><b>Personal Information:</b> This includes your name, email address, phone number, and any other details you provide when you register, make an inquiry, or engage with our services.</p>
                        <p><b>Non-Personal Information:</b> We may gather information that does not identify you personally, such as your IP address, browser type, and usage data to help us improve our website and services.</p>

                        <h3><b><u>3. How We Use Your Information</u></b></h3>
                        <p><b>We may use your information for the following purposes:</b></p>
                        <p><b>-</b>To provide and manage our services.</p>
                        <p><b>-</b>To communicate with you, including responding to inquiries and sending updates.</p>
                        <p><b>-</b>To improve our website and services based on user feedback.</p>
                        <p><b>-</b>To comply with legal obligations and protect our rights.</p>

                        <h3><b><u>4. How We Use Your Information</u></b></h3>
                        <p><b>We do not sell, trade, or otherwise transfer your personal information to outside parties, except in the following circumstances:</b></p>
                        <p>With trusted third parties who assist us in operating our website or conducting our business, provided they agree to keep this information confidential.</p>
                        <p>When required by law or to protect our rights and safety or that of others.</p>

                        <h3><b><u>5. Data Security</u></b></h3>
                        <p>We implement a variety of security measures to maintain the safety of your personal information. While we strive to protect your personal information, no method of transmission over the Internet or method of electronic storage is 100% secure.</p>

                        <h3><b><u>6. Your Rights</u></b></h3>
                        <p><b>You have the right to:</b></p>
                        <p><b>-</b>Access your personal information.</p>
                        <p><b>-</b>Request corrections to your personal information.</p>
                        <p><b>-</b>Request deletion of your personal information</p>
                        <p><b>-</b>Withdraw consent for the processing of your personal information</p>
                        <p><b>-</b>To exercise these rights, please contact us using the information below.</p>

                        <h3><b><u>7. Changes to This Privacy Policy</u></b></h3>
                        <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on our website. We encourage you to review this Privacy Policy periodically for any updates.</p>

                        <h3><b><u>Contact Us</u></b></h3>
                        <p>If you have any questions or concerns about this Privacy Policy or our practices, please contact us at:</p>
                        <p><b>Sarthak Jeevan Nasa Mukti Kendra</b><br />Itta Factory, Sampatchak, Thana - Gopalpura, Bariya, Patna- 800007</p>
                        <p><i className='fa fa-phone'></i> Mobile: 9471414102<br /><i className='fa fa-envelope-o'></i> Email: sarthakjeevannasamuktikendra@gmail.com</p>
                        {/*
                        <p>
                          <i className='fa fa-envelope-o'></i> Email:
                        <a href="mailto: sarthakjeevannasamuktikendra@gmail.com"> <u>sarthakjeevannasamuktikendra@gmail.com</u></a>
                      </p> 
                      <p>
                      <i className='fa fa-phone'></i> Phone:
                      <a href="tel: 9471414102"> <u>9471414102</u> </a>
                      </p>      
                        */}
                    </div>

                </div>

            </div>
        </div>
    )
}