import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";

import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";



//import MediaCard from "./components/future/mediacard"

import { green } from '@mui/material/colors';
import Crous2 from "./components/Crous2";
import Chooseus from "./components/chooseus";


import Certifications from "./components/Certifications";
import { Servicelink2 } from "./components/servicelink2";


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

{/*
const fabStyle = {
  position: 'absolute',
  bottom: 16,
  right: 16,
};
 const fabGreenStyle = {
    color: 'common.white',
    bgcolor: green[500],
    '&:hover': {
      bgcolor: green[600],
    },
  };

*/}


const Home = () => {
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
      setLandingPageData(JsonData);
    }, []);
    
  
    return (
      <div>
      <Header data={landingPageData.Header}  />
   
      <About data={landingPageData.About} />
      <Features data={landingPageData.Features} />
      <Certifications data={landingPageData.Therapy} />
      <Team data={landingPageData.Team} />
      

        {/*<Crous /> 
        <Crous2 /> 
        <Caurousel />
        <Gallery data={landingPageData.Gallery}/>
        <Header data={landingPageData.Header} />
        for whatspp web use link href="https://wa.me/9060591201"

       
      
           
        <Servicelink2 data={landingPageData.Services} />
      */}
        
        
        
    
        
        
        <Services data={landingPageData.Services} />
        <Contact data={landingPageData.Contact} />
        
        <Testimonials data={landingPageData.Testimonials} />
      </div>
    );
  
}


export default Home;
