import { useState, useEffect } from "react";
import { Route, Routes } from 'react-router-dom';
import JsonData from "./data/data.json";


import SmoothScroll from "smooth-scroll";
import "./App.css";
import { Navigation } from "./components/navigation";
import Home from "./Home";
import { Errorpage } from "./components/pages/errorpage";
import { Thanks } from "./components/pages/thanks";
import { Footer } from "./components/footer";
import Footerdetail from "./components/Footerdetail";
import { Returnypolicy } from "./components/pages/returnpolicy";
import { TermsCondition } from "./components/pages/termscondition";
import { Faqp } from "./components/pages/faqp";
import { Privacypolicy } from "./components/pages/privacypolicy";
import { Helpcenter } from "./components/pages/helpcenter";
import { AboutDetail } from "./components/pages/aboutdetail";
import { PrivacyPolicies2 } from "./components/privacypolicy2";


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});


const App = () => {


  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      
        <div className='social'>
        <div>
          <a className="whatsapp" href="https://api.whatsapp.com/send?phone=919044605877" target="_blank">
            <i className="fa fa-whatsapp"></i>
          </a>
        </div>
        <div>
          <a className="phone" href="tel:9044605877">
            <i className="fa fa-phone"></i>
          </a>
        </div>
      </div>
        
      <Navigation />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<Privacypolicy />} />
        <Route exact path="/uddesh" element={<AboutDetail data={landingPageData.Uddes} />} />
        
       
      </Routes>
   
      {/*
        
      <Route exact path="*" element={<Errorpage />} />
        <Route exact path="/thanks" element={<Thanks />} />
        <Route path="/privacy-policy" element={<Privacypolicy />} />
        <Route path="/return-policy" element={<Returnypolicy />} />
        <Route path="/help-center" element={<Helpcenter />} />
        <Route path="/terms-condition" element={<TermsCondition />} />
        <Route path="/faq" element={<Faqp />} />
        <Footerdetail data={landingPageData.Contact} />
        */}
     
      
      <Footer data={landingPageData.Footerdetail} />
    </div>

  );
};


export default App;

