import React from 'react'

const Certifications = (props) => {
  return (
    <div id='certifications' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>Our Therepy</h2>
          <h3>If you are suffering from an addiction, you will recognize the hugely damaging impact that it can have upon relationships, as well as your career and finances. Confidential help, from public Roarwellness Rehab, to find substance use treatment and information.</h3>

        </div>

        <div id='row gacontain'>
          {props.data
            ? props.data.map((d, i) => (
              <div key={`${d.name}-${i}`} className='col-md-4 col-sm-12 team'>
                <div className='thumbnail'>
                  {' '}
                  <img src={d.imgsrc} alt='...' className='team-img lazyloaded' />
                  <h3>{d.title}</h3>

                </div>
              </div>
            ))
            : 'loading'}
        </div>

        

      </div>
    </div>
  )
}

export default Certifications;
